.br-theme-fontawesome-stars .br-widget {
	height: 28px;
	white-space: nowrap;
}
.br-theme-fontawesome-stars .br-widget a {
	font: normal normal normal 20px/1 'Font Awesome 5 Free';
	font-weight: 600;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	text-decoration: none;
	margin-right: 2px;
	font-size: 1.5rem;
}
.br-theme-fontawesome-stars .br-widget a:after {
	content: '\f005';
	color: #d2d2d2;
}
.br-theme-fontawesome-stars .br-widget a.br-active:after {
	color: #ff5501;
}
.br-theme-fontawesome-stars .br-widget a.br-selected:after {
	color: #ff5501;
}
.br-theme-fontawesome-stars .br-widget .br-current-rating {
	display: none;
}
.br-theme-fontawesome-stars .br-readonly a {
	cursor: default;
}
@media print {
	.br-theme-fontawesome-stars .br-widget a:after {
		content: '\f006';
		color: black;
	}
	.br-theme-fontawesome-stars .br-widget a.br-active:after,
	.br-theme-fontawesome-stars .br-widget a.br-selected:after {
		content: '\f005';
		color: black;
	}
}
